<!-- start page title section -->
<section class="wow animate__fadeIn parallax padding-55px-tb" data-parallax-background-ratio="0.5" style="background-image:url('assets/images/main_03.jpg');">
  <div class="opacity-medium bg-extra-dark-gray"></div>
  <div class="container position-relative">
    <div class="row align-items-center">
      <div class="col-12 d-flex flex-column justify-content-center text-center extra-small-screen page-title-large">
        <!-- start page title -->
        <h1 class="text-white-2 alt-font font-weight-600 letter-spacing-minus-1 margin-10px-bottom">商务考察</h1>
        <!-- end page title -->
        <!-- start sub title -->
        <span class="text-white-2 opacity6 alt-font">助您先人一步，决策无限可能</span>
        <!-- end sub title -->
      </div>
    </div>
  </div>
</section>
<!-- end page title section -->

<!-- start portfolio section -->
<section id="business-portfolio" class="padding-90px-top bg-light-gray md-padding-50px-top sm-padding-30px-top">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- start filter navigation -->
        <ul class="portfolio-filter nav nav-tabs justify-content-center border-0 portfolio-filter-tab-1 font-weight-600 alt-font text-uppercase text-center margin-80px-bottom text-small md-margin-40px-bottom sm-margin-20px-bottom">
          <li [ngClass]="{'active': isFilterActiv('')}" class="nav"><a href="javascript:void(0);" data-filter="*" class="light-gray-text-link text-very-small">全部</a></li>
          <li [ngClass]="{'active': isFilterActiv('czkz')}" class="nav"><a href="javascript:void(0);" data-filter=".czkz" class="light-gray-text-link text-very-small">参展看展</a></li>
          <li [ngClass]="{'active': isFilterActiv('jmjl')}" class="nav"><a href="javascript:void(0);" data-filter=".jmjl" class="light-gray-text-link text-very-small">经贸交流</a></li>
          <li [ngClass]="{'active': isFilterActiv('xmqt')}" class="nav"><a href="javascript:void(0);" data-filter=".xmqt" class="light-gray-text-link text-very-small">项目洽谈</a></li>
          <li [ngClass]="{'active': isFilterActiv('szsy')}" class="nav"><a href="javascript:void(0);" data-filter=".szsy" class="light-gray-text-link text-very-small">双招双引</a></li>
          <li [ngClass]="{'active': isFilterActiv('pxxx')}" class="nav"><a href="javascript:void(0);" data-filter=".pxxx" class="light-gray-text-link text-very-small">培训学习</a></li>
          <li [ngClass]="{'active': isFilterActiv('xctj')}" class="nav"><a href="javascript:void(0);" data-filter=".xctj" class="light-gray-text-link text-very-small">宣传推介</a></li>
        </ul>
        <!-- end filter navigation -->
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 filter-content overflow-hidden">
        <ul class="hover-option6 lightbox-portfolio portfolio-wrapper grid grid-loading grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-medium">
          <li class="grid-sizer"></li>
          <!-- start portfolio-item item -->
          <li  *ngFor="let item of DataJson; let i = index" [ngClass]="{'wow animate__fadeInUp': isWowLoaded}" class="{{item.category}} grid-item last-paragraph-no-margin" [attr.data-wow-delay]="getDelay(i)">
            <figure>
              <div class="portfolio-img bg-deep-pink position-relative text-center overflow-hidden">
                <a (click)="openBlog(item)">
                  <img src="assets/images/{{item.url}}" alt=""/>
                </a>
                <div class="portfolio-icon">
                  <p class="text-white text-medium padding-20px-lr">{{getBlogInfo(item)}}</p>
                </div>
              </div>
              <figcaption class="bg-white">
                <div class="portfolio-hover-main text-center">
                  <div class="portfolio-hover-box align-middle">
                    <div class="portfolio-hover-content position-relative">
                      <a (click)="openBlog(item)" ><span class="line-height-normal font-weight-600 text-medium alt-font margin-5px-bottom text-extra-dark-gray text-uppercase d-block">{{item.header}}</span></a>
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          </li>
          <!-- end portfolio item -->
        </ul>
      </div>
    </div>
  </div>
  <!-- end filter content -->
</section>
<!-- end portfolio section -->
