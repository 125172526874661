import {Component, OnInit, ViewChild} from '@angular/core';
import { AbstractContentComponent } from '../abstract.component';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ContactService } from 'src/app/services/index';
import { ResponseMsg } from 'src/app/models';
import { ReCaptcha2Component } from 'ngx-captcha';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends AbstractContentComponent implements OnInit {
  aFormGroup: FormGroup;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(public formBuilder: FormBuilder, public contactService: ContactService, private snackBar: MatSnackBar) {
    super();
  }

  ngOnInit(): void {
    this.initialLayout();
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      username: ['', Validators.required],
      title: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  get username(): AbstractControl {
    return this.aFormGroup.get('username');
  }

  get title(): AbstractControl {
    return this.aFormGroup.get('title');
  }

  get email(): AbstractControl {
    return this.aFormGroup.get('email');
  }

  get message(): AbstractControl {
    return this.aFormGroup.get('message');
  }

  send(): void {
    this.contactService.submitContact(this.aFormGroup.value).subscribe((res: ResponseMsg) => {
      this.captchaElem.reloadCaptcha();
      this.aFormGroup.setValue({
        recaptcha: '',
        email: '',
        username: '',
        title: '',
        message: ''
      });
      if (res.status === 1) {
        this.snackBar.open(res.message, 'close', {duration: 5000});
      } else {
        this.snackBar.open(res.errors.join(';'), 'close', {duration: 5000});
      }
    });
  }


}
