<label class="label-block"><i class="material-icons">bed</i>{{ label }}</label>
<div #selector class="selection dropdown selection__changed">
  <div  class="selection__summary">
    <button class="selection__control" (click)="openDialog(selector)">
      <div class="wrapper">
        <div class="selection__placeholder">{{ getRommsInfo() }}</div>
      </div>
    </button>
  </div>
</div>
