export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBRq4yJ0T3huwc0xw_eXXSaTi86PonhIAg',
    authDomain: 'yiouyuan-43d51.firebaseapp.com',
    databaseURL: 'https://yiouyuan-43d51-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'yiouyuan-43d51',
    storageBucket: 'yiouyuan-43d51.appspot.com',
    messagingSenderId: '291359057623',
    appId: '1:291359057623:web:7093c6c85b830b5b867292',
    measurementId: 'G-YX4CFYC01Q'
  },
  credential: {
    email: 'info@yiouyuan.com',
    password: '!Z3!Mkn055Z5'
  }
};
