<div id="wrapper" class="app-container">
  <!--PRELOADER START-->
  <div class="preloader" *ngIf="loadingService.getLoading()" [@fadeOutAnimation]>
    <div class="loading" [@fadeAnimation]>
      <span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </span>
      <div class='base'>
        <span></span>
        <div class='face'></div>
      </div>
    </div>
    <div class='longfazers'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <h1>Loading</h1>
  </div>
  <!--PRELOADER END-->
  <!-- start navigation -->
  <header>
    <nav class="navbar no-margin-bottom bootsnav alt-font bg-white header-light sidebar-nav sidebar-nav-style-1 navbar-expand-lg">
    <!-- start logo -->
    <div class="col-12 sidenav-header">
      <div class="logo-holder">
        <a href="#" class="d-inline-block logo">
          <img  (click)="goToUrl('home')" class="logo-dark mobile" src="assets/images/logo_txt_orange.png" data-at2x="assets/images/logo_txt_orange@2x.png" />
          <img  (click)="goToUrl('home')" class="logo-light mobile default" src="assets/images/logo_txt_white.png" data-at2x="assets/images/logo_txt_white@2x.png" />
          <img  (click)="goToUrl('home')" class="desktop" src="assets/images/logo_txt.png" data-at2x="assets/images/logo_txt@2x.png" />
        </a>
      </div>
      <!-- end logo -->
      <button class="navbar-toggler mobile-toggle" type="button" id="mobileToggleSidenav">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <!-- end logo -->
    <div class="col-12 px-0">
      <div id="navbar-menu" class="collapse navbar-collapse no-padding">
        <ul class="nav navbar-nav navbar-left-sidebar font-weight-500">
          <li class="dropdown" [ngClass]="{'active': whereAmI === 'home'}">
            <a [routerLink]="" (click)="goToUrl('home')" >首页<i class="fas fa-angle-right pull-right"></i></a>
          </li>
          <li class="dropdown" [ngClass]="{'active': whereAmI === 'custom'}">
            <a [routerLink]="" (click)="goToUrl('custom')">私人订制<i class="fas fa-angle-right pull-right"></i></a>
            <ul class="dropdown-menu second-level">
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('schedule', 3, 'fr')">法国浪漫之旅13日游<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('schedule', 3, 'it')">意大利文艺之旅13日游<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('schedule', 3, 'de')">德国童话之旅9日游<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('schedule', 3, 'football')">足球朝圣之旅12日游<i class="fas fa-angle-right pull-right"></i></a>
              </li>
            </ul>
          </li>
          <li class="dropdown" [ngClass]="{'active': whereAmI === 'study'}">
            <a [routerLink]="" (click)="goToUrl('study')">欧洲游学<i class="fas fa-angle-right pull-right"></i></a>
          </li>
          <li class="dropdown" [ngClass]="{'active': whereAmI === 'business'}">
            <a [routerLink]="" (click)="goToUrl('business', 3, '')">商务考察<i class="fas fa-angle-right pull-right"></i></a>
            <ul class="dropdown-menu second-level">
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('business', 3, 'czkz')">参展看展<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('business', 3, 'jmjl')">经贸交流<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('business', 3, 'xmqt')">项目洽谈<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('business', 3, 'szsy')">双招双引<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('business', 3, 'pxxx')">培训学习<i class="fas fa-angle-right pull-right"></i></a>
              </li>
              <li class="dropdown">
                <a [routerLink]="" (click)="goToUrl('business', 3, 'xctj')">宣传推介<i class="fas fa-angle-right pull-right"></i></a>
              </li>
            </ul>
          </li>
          <li class="dropdown" [ngClass]="{'active': whereAmI === 'contact'}">
            <a [routerLink]="" (click)="goToUrl('contact')">联系我们<i class="fas fa-angle-right pull-right"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 position-absolute top-auto bottom-0 left-0 w-100 padding-20px-bottom sm-padding-15px-bottom">
      <div class="footer-holder">
        <div class="icon-social-medium margin-eleven-bottom padding-eight-top sm-padding-15px-top sm-margin-15px-bottom">
          <a href="https://www.facebook.com/yiouyuan" target="_blank" class="text-link-extra-dark-gray"><i class="fab fa-facebook-f text-link-active-color" aria-hidden="true"></i></a>
          <a href="https://twitter.com/yiouyuan" target="_blank" class="text-link-extra-dark-gray"><i class="fab fa-twitter text-link-active-color" aria-hidden="true"></i></a>
          <a href="#modal-popup1" class="popup-with-zoom-anim text-link-extra-dark-gray"><i class="fab fa-weixin text-link-active-color" aria-hidden="true"></i></a>
          <a href="#modal-popup1" class="popup-with-zoom-anim text-link-extra-dark-gray"><i class="fab fa-tiktok text-link-active-color" aria-hidden="true"></i></a>
        </div>
        <div class="text-medium-gray text-extra-small border-top border-color-extra-light-gray padding-twelve-top sm-padding-15px-top">2023 YIOUYUAN All rights reserved</div>
      </div>
    </div>
  </nav>
  </header>
  <!-- start weixin modal pop-up -->
  <div id="modal-popup1" class="modal_weixin col-11 col-xxl-3 col-xl-4 col-lg-6 col-md-7 col-sm-9 zoom-anim-dialog mfp-hide mx-auto bg-white text-center modal-popup-main padding-50px-all">
  </div>
  <!-- end modal pop-up -->
  <div class="sidebar-wrapper mobile-height">

    <!--  <router-outlet></router-outlet>-->
    <ng-template appContentHost></ng-template>

    <!-- start footer -->
    <footer class="footer-center-logo bg-light-gray padding-two-tb sm-padding-30px-tb">
      <div class="container">
        <div class="row align-items-center">
          <!-- start copyright -->
          <div class="col-lg-4 col-md-5 text-small text-center alt-font sm-margin-15px-bottom">
            2023 yiouyuan is Powered by <a href="https://www.kewei-group.com" target="_blank" title="kewei">Kewei GmbH</a>
          </div>
          <!-- end copyright -->
          <!-- start logo -->
          <div class="col-lg-4 col-md-2 text-center sm-margin-10px-bottom">
            <a (click)="goToUrl('home')"><img class="footer-logo" src="assets/images/logo_footer.png" data-at2x="assets/images/logo_footer@2x.png" alt="Pofo"></a>
          </div>
          <!-- end logo -->
          <!-- start social media -->
          <div class="col-lg-4 col-md-5 text-center">
            <div class="social-icon-style-8 d-inline-block align-middle">
              <ul class="small-icon mb-0">
                <li><a class="facebook" href="https://www.facebook.com/yiouyuan" target="_blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                <li><a class="twitter" href="https://twitter.com/yiouyuan" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a title="WeChat" href="#modal-popup1" class="popup-with-zoom-anim"><i class="fab fa-weixin"></i></a></li>
                <li><a title="Tiktok" href="#modal-popup1" class="popup-with-zoom-anim"><i class="fab fa-tiktok me-0" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>
          <!-- end social media -->
        </div>
      </div>
    </footer>
    <!-- end footer -->
  </div>
</div>
