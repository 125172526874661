<div class="selection selection__format noMargin">
  <div class="selection__content with-split maxwidth">
    <h4 class="selection__headline">
      {{ data.title }}
      <span class="selection__close" (click)="onNoClick()">
        <i class="material-icons">close</i>
      </span>
    </h4>
    <div class="selection__container--horizontal">
      <div>
      <mat-card *ngFor="let room of rooms">
        <mat-card-header>
          <mat-card-title>
            <div>房间 {{room.id}}<span *ngIf="room.id > 1" class="material-icons" (click)="removeRoom(room.id)">close</span></div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="select-people">
            <div class="people-container">
              <div class="label-container">成人<span>(12岁以上)</span></div>
              <span class="label-count-container">{{room.adults}}</span>
            </div>
            <mat-slider
              class="margin"
              [max]="4"
              [min]="1"
              [step]="1"
              [color]="'warn'"
              [tickInterval]="1"
              [(ngModel)]="room.adults"
              [thumbLabel]="false">
            </mat-slider>
          </div>
          <div class="select-people">
            <div class="people-container">
              <div class="label-container">儿童<span>(0 - 11岁)</span></div>
              <span class="label-count-container">{{room.kids}}</span>
            </div>
            <mat-slider
              class="margin"
              [max]="4"
              [min]="0"
              [step]="1"
              [color]="'warn'"
              [tickInterval]="1"
              [(ngModel)]="room.kids"
              [thumbLabel]="false">
            </mat-slider>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <div class="icon-container">
            <div class="icon-adult">
              <fa-icon  *ngFor="let item of [].constructor(room.adults)" [icon]="faMale"></fa-icon>
            </div>
            <div class="icon-kids">
              <fa-icon *ngFor="let item of [].constructor(room.kids)" [icon]="faBaby"></fa-icon>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
      </div>
    </div>
    <div class="selection__buttonbar">
      <div class="selection__buttonbar--left"></div>
      <div class="selection__buttonbar--right">
        <button *ngIf="rooms.length < 3" class="selection__button button--orange" (click)="addRoom(rooms.length + 1)">
          <span class="material-icons">add</span>加房间
        </button>
        <button class="selection__button button--orange" [mat-dialog-close]="rooms">
          确定
        </button>
      </div>
    </div>
  </div>
</div>
