import { Component, OnInit } from '@angular/core';
import {AbstractContentComponent} from "../abstract.component";
import {Blog, DwhApi} from 'src/app/models/index';
import {LoadingService} from "../../services";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent extends AbstractContentComponent implements OnInit {

  url = '';
  DataJson: Blog;

  constructor(public loadingService: LoadingService, private http: HttpClient) {
    super();
    this.loadingService.showLoading(true);
  }

  ngOnInit(): void {
    this.initialLayout();
    this.url = "/assets/data/data.json";
    this.http.get(this.url).subscribe((res: DwhApi) => {
      this.DataJson = res.business.find(x => x.id === this.name);
      this.loadingService.showLoading(false);
    });
  }

}
