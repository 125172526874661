import { Component, OnInit } from '@angular/core';

import { AbstractContentComponent } from '../abstract.component';

import { Slide } from 'src/app/models/index';

import { DatabaseService } from 'src/app/services/index';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-photography',
  templateUrl: './photography.component.html',
  styleUrls: ['./photography.component.scss']
})
export class PhotographyComponent extends AbstractContentComponent implements OnInit {

  blogSlideData: Slide[];

  constructor(public dataService: DatabaseService) { super(); }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.dataService.getListByName('/slider/blog')
      .valueChanges()
      .subscribe(x => {
        if (x != null) {
          this.blogSlideData = x;
        }
      });
  }

}
