import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { Option } from 'src/app/models/components';

@Component({
  selector: 'app-yoy-dropdown',
  templateUrl: './yoy-dropdown.component.html',
  styleUrls: ['./yoy-dropdown.component.scss'],
  styles: [':host { font-size: .875em; }']
})
export class YoyDropdownComponent implements OnInit, OnChanges {

  @Input() label: string;
  @Input() icon: string;
  @Input() options: Option[] = null;
  @Input() withall = false;

  @Output()
  changeSetting: EventEmitter<string | number> = new EventEmitter<string | number>();

  listVisible = false;
  res: Option[];
  defaultValue: Option[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
    this.change();
  }

  /**
   * show dropdown select item list
   */
  show(): void {
    this.listVisible = true;
  }

  /**
   * hide dropdown select item list
   */
  hide(): void {
    this.listVisible = false;
  }

  /**
   * Toggle drowdown view
   */
  toggle(): void {
    this.listVisible = !this.listVisible;
  }

  /**
   * change selected item
   */
  change(global: boolean = false): void {
    if (this.options !== null && this.options?.filter( (x) => x.selected === true).length > 0) {
      this.res = this.options.filter( (x) => x.selected === true );
      if (global === true) {
        this.changeSetting.emit(this.res[0].value);
      }
    } else {
      this.res = null;
    }
  }

  /**
   * Remove select option from option list
   * @param item Option
   */
  remove(item: Option): void {
    this.options.find((x) => x.id === item.id).selected = false;
    this.res = null;
  }

  /**
   * select option from option list
   * @param item Option
   */
  select(item: Option): void {
    this.options.map((x) => { x.selected = false; });
    this.options.find((x) => x.id == item.id).selected = !item.selected;
    this.change(true);
    this.hide();
  }

  /**
   * reset option to default
   */
  reset(): void {
    this.options.map((x) => { x.selected = false; });

    this.defaultValue = this.options.filter((x) => Boolean(x.default) === true);
    if (this.defaultValue.length > 0) {
      for (const item of this.defaultValue) {
        if (item.id) {
          this.options.find((x) => x.id === item.id).selected = true;
        }
      }
      this.res = this.defaultValue;
    } else {
      this.res = null;
    }
  }


}
