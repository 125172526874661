<!-- start page title section -->
<section class="wow animate__fadeIn bg-light-gray padding-35px-tb md-padding-65px-top page-title-small top-space">
  <div class="container position-relative">
    <div class="row align-items-center">
      <div class="col-xl-8 col-md-6 d-flex flex-column justify-content-center text-center text-md-start">
        <!-- start page title -->
        <h1 class="alt-font text-extra-dark-gray font-weight-600 mb-0 text-uppercase">{{DataJson?.title}}</h1>
        <!-- end page title -->
      </div>
      <div class="col-xl-4 col-md-6 alt-font breadcrumb justify-content-center justify-content-md-end text-small sm-margin-10px-top">
        <!-- breadcrumb -->
        <ul class="text-center text-md-start text-uppercase">
          <li><span class="text-dark-gray">{{DataJson?.date}}</span></li>
        </ul>
        <!-- end breadcrumb -->
      </div>
    </div>
  </div>
</section>
<!-- end page title section -->
<!-- start section -->
<section class="wow animate__fadeIn">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-9 last-paragraph-no-margin">
        <img src="assets/images/blog/{{DataJson?.url}}" alt="" class="w-100 margin-50px-bottom md-margin-30px-bottom">
        <h5 class="alt-font font-weight-600 text-extra-dark-gray">{{DataJson?.header}}</h5>
        <p class="text-large line-height-30 text-medium-gray sm-line-height-26" [innerHTML]="DataJson?.content"></p>
      </div>
    </div>
  </div>
</section>
<!-- end section -->
