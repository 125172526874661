import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractContentComponent } from '../abstract.component';

import { Slide, PortfolioItem } from 'src/app/models/index';

import { DatabaseService, LoadingService } from 'src/app/services/index';

// declare the javascript function here
declare function loadPage(): void;
declare function loadPortfolio(name: string): void;

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent extends AbstractContentComponent implements OnInit {

  url:string = '/assets/data/data.json'
  isWowLoaded:boolean = false;
  businessSlideData: Slide[];
  DataJson: PortfolioItem[];

  constructor(public dataService: DatabaseService, public  loadingService: LoadingService, private http: HttpClient) {
    super();
    this.loadingService.showLoading(true);
  }

  ngOnInit(): void {
    this.initialLayout();
    this.http.get(this.url).subscribe(res => {
      this.DataJson = res['business'];
      setTimeout(()=>{
        this.isWowLoaded = true;
        loadPage();
        loadPortfolio(this.name);
      })
      this.loadingService.showLoading(false);
    });
  }

  getDelay(i: number): string {
    return "0." + (i%4)*2 + "s";
  }

  isFilterActiv(name: string): boolean {
    if (this.name === name) {
      return true;
    }
    return false;
  }

  getBlogInfo(item): string {
    if(item.id) {
      return item.title;
    }
    return item.content;
  }

  openBlog(item):void {
    if(item.id) {
      this.linkToBlog.emit(item.id);
    }
  }

}
