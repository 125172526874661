<label class="label-block"><i class="material-icons">travel_explore</i>{{ label }}</label>
<div #selector class="selection dropdown selection__changed">
  <div  class="selection__summary">
    <button class="selection__control" (click)="openDialog(selector)">
      <div class="wrapper">
        <div class="selection__placeholder">{{getSelectedCountries()}}</div>
      </div>
    </button>
    <span class="count" *ngIf="res?.length > 1">{{res?.length}}</span>
    <span class="reset" *ngIf="res?.length > 1">
      <i class="material-icons" (click)="removeAll()">close</i>
    </span>
  </div>
</div>
