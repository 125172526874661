import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChange, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Country } from 'src/app/models/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-yoy-country-select',
  templateUrl: './yoy-country-select.component.html',
  styleUrls: ['./yoy-country-select.component.scss'],
  styles: [':host { font-size: .875em; }']
})
export class YoyCountrySelectComponent implements OnInit {

  @Input() label: string;
  @Input() options: Country[] = null;
  @Input() withall = false;

  @Output()
  changeSetting: EventEmitter<string | number> = new EventEmitter<string | number>();

  listVisible = false;
  res: Country[];
  defaultValue: Country[];

  constructor( public dialog: MatDialog ) { }

  ngOnInit(): void {

  }

  getSelectedCountries(): string {
    let label = '';
    this.res?.map((x) => {
      label += x.label + ',';
    });

    return ((label === '') ? '无特别要求' : label.slice(0, -1));
  }

  removeAll(): void {
    this.options.map((x) => x.selected = false);
    this.res = [];
  }

  openDialog(clickTarget: any = null): void {
    const config   = {
      width: '40rem',
      data: {
        title:    '选择目的地',
        label:    'label',
        name:     'name',
        options:  this.options
      }
    }

    const dialogRef = this.dialog.open(YoyCountrySelectDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.res = result?.filter((x) => x.selected);
      }
    });
  }

}

@Component({
  selector: 'app-yoy-country-select-dialog',
  templateUrl: './yoy-country-select-dialog.html',
  styleUrls: ['./yoy-country-select.component.scss']
})
export class YoyCountrySelectDialogComponent implements OnInit, OnDestroy{

  term: string;
  loadoptions: Country[];

  constructor(public dialogRef: MatDialogRef<YoyCountrySelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.loadoptions = null;
  }

  ngOnDestroy(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  select(item: Country): void {

    if (this.data.multi === false) {
      this.reset();
    }

    this.data.options.find((x) => x.code === item.code).selected = !item.selected;

    if (this.data.multi === false) {
      this.onNoClick();
    }
  }

  remove(item: Country): void {
    if (this.data.multi === false) {
      this.reset();
    }

    this.data.options.find((x) => x.code === item.code).selected = !item.selected;

    if (this.data.multi === false) {
      this.onNoClick();
    }
  }

  reset(): void {
  }

  count(): number {
    return this.data.options.filter((x) => x.selected === true).length;
  }

  countSelected(attr: string): number {
    return this.data.options.filter((x) => x.selected === true && x.role === attr).length;
  }

  getCountry(): Country[] {
    return this.data.options.filter((x) => x.visible === true);
  }

  haveCity(code: string): boolean {
    if (this.data.options.find((x) => x.role === 'city' && x.country === code)) {
      return true;
    }
    return false;
  }

  showCity(code: string, open: boolean): void {
    this.data.options.find((x) => x.role === 'country' && x.code === code).open = !open;
    this.data.options.map((x) => {
      if (x.role === 'city' && x.country === code) {
        x.visible = !open;
      }});
  }

  resetTerm(): void {
    this.term = '';
  }
}
