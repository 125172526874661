<div class="selection selection__format noMargin">
  <div class="selection__content with-split maxwidth">
    <h4 class="selection__headline">
      {{ data.title }}
      <span class="selection__close" (click)="onNoClick()">
        <i class="material-icons">close</i>
      </span>
    </h4>
    <div class="selection__container--horizontal">
      <div class="selection__body with-split--left">
        <div class="selection__filter">
          <div class="fieldset--block">
            <div class="fieldset__border active" [ngClass]="{'active': term}">
              <div class="fieldset__input">
                <span *ngIf="term" class="fieldset__hint">名字:</span>
                <input type="text" name="term" [(ngModel)]="term" placeholder="搜索" />
                <i *ngIf="term" class="material-icons" (click)="resetTerm()">close</i>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="list">
            <div *ngFor="let item of getCountry() | yoySelectFilterPipe : 'label' : term "
                 class="option" [ngClass]="{'active': item.selected}">
              <div class="open-country" (click)="showCity(item.code, item.open)" >
                <i *ngIf="haveCity(item.code)" class="material-icons">{{(item.open) ? 'remove' : 'add'}}</i>
              </div>
              <div class="name" (click)="select(item)">
                <span class="flag-icon flag-icon-{{item.code.toLowerCase()}}"></span> <span class="text">{{ item.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="selection__body with-split--right">
        <div class="selection__headline-2">已选择 {{ countSelected('country') }} 个国家, {{ countSelected('city') }} 个城市</div>
        <div class="content">
          <div class="list">
            <div class="option selected spacebetween" *ngFor="let item of data.options | yoySelectFilterPipe : 'selected' : true" (click)="remove(selecteditem)" >
              <div><span class="flag-icon flag-icon-{{item.code.toLowerCase()}}"></span> <span class="text">{{ item.label }}</span></div>
              <i class="material-icons" (click)="remove(item)">close</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="selection__buttonbar">
      <div class="selection__buttonbar--left"></div>
      <div class="selection__buttonbar--right">
        <button *ngIf="data.multi" class="selection__button button--white" (click)="reset()">
          reset
        </button>
        <button class="selection__button button--orange" [mat-dialog-close]="data.options">
          确认
        </button>
      </div>
    </div>
  </div>
</div>
