<!-- start page title section -->
<section class="wow animate__fadeIn parallax padding-55px-tb" data-parallax-background-ratio="0.5" style="background-image:url('assets/images/contact.png');">
  <div class="opacity-medium bg-extra-dark-gray"></div>
  <div class="container position-relative">
    <div class="row align-items-center">
      <div class="col-12 d-flex flex-column justify-content-center text-center extra-small-screen page-title-large">
        <!-- start page title -->
        <h1 class="text-white-2 alt-font font-weight-600 letter-spacing-minus-1 margin-10px-bottom">联系我们</h1>
        <!-- end page title -->
        <!-- start sub title -->
        <span class="text-white-2 opacity6 alt-font">联系我们，开启精彩之旅</span>
        <!-- end sub title -->
      </div>
    </div>
  </div>
</section>
<!-- end page title section -->

<!-- start form section -->
<section class="wow animate__fadeIn padding-65px-top" id="start-your-project">
  <div class="container">
    <form [formGroup]="aFormGroup">
      <div class="row">
        <div class="col-12">
          <div class="form-results d-none"></div>
        </div>
        <div class="col-12 col-md-6 margin-20px-bottom">
          <input type="text" name="name" id="name" placeholder="姓名 *" formControlName="username" class="big-input required">
          <div class="form-group-error text-small text-active-color" *ngIf="!username?.valid && (username?.dirty ||username?.touched)">
            <div [hidden]="!username.errors.required">
              * 请填写姓名
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 margin-20px-bottom">
          <input type="email" name="email" id="email" placeholder="E-mail *" formControlName="email" class="big-input required">
          <div class="form-group-error text-small text-active-color" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">
            <div [hidden]="!email.errors.required">
              * 请填写邮箱
            </div>
            <div [hidden]="!email.errors.email">
              * 请填写有效的邮箱地址
            </div>
          </div>
        </div>
        <div class="col-12 margin-20px-bottom">
          <input type="tel" id="subject" name="subject" placeholder="标题 *" formControlName="title" class="big-input" required>
          <div class="form-group-error text-small text-active-color" *ngIf="!title?.valid && (title?.dirty ||title?.touched)">
            <div [hidden]="!title.errors.required">
              * 请填写标题
            </div>
          </div>
        </div>
        <div class="col-12 margin-20px-bottom">
          <textarea name="comment" id="comment" formControlName="message" placeholder="内容 *" rows="6" class="big-textarea" required></textarea>
          <div class="form-group-error text-small text-active-color" *ngIf="!message?.valid && (message?.dirty ||message?.touched)">
            <div [hidden]="!message.errors.required">
              * 请填写内容
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <ngx-recaptcha2 #captchaElem
                          required
                          siteKey="6Lf9Mo4iAAAAAAZL_oBw0X8pc77zia4AD9dhkx2j"
                          [type]="'image'"
                          [useGlobalDomain]="false"
                          formControlName="recaptcha">
          </ngx-recaptcha2>
        </div>
        <div class="col-12 text-center">
          <button id="project-contact-us-button" [disabled]="aFormGroup.invalid" type="submit" class="btn btn-transparent-deep-pink btn-large margin-20px-top submit" (click)="send()">提交</button>
        </div>
      </div>
    </form>
  </div>
</section>
<!-- end form section -->

<!-- start contact info -->
<section class="wow animate__fadeIn no-padding-top">
  <div class="container px-0">
    <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-3">
      <!-- start contact info item -->
      <div class="col text-center md-margin-eight-bottom sm-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin">
        <div class="d-inline-block margin-20px-bottom">
          <div class="bg-active-color icon-round-medium"><i class="icon-map-pin icon-medium text-white-2"></i></div>
        </div>
        <div class="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">联系地址</div>
        <p class="mx-auto">Mörfelder Str. 22C 65451<br>Kelsterbach Germany</p>
      </div>
      <!-- end contact info item -->
      <!-- start contact info item -->
      <div class="col text-center md-margin-eight-bottom sm-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin" data-wow-delay="0.2s">
        <div class="d-inline-block margin-20px-bottom">
          <div class="bg-active-color icon-round-medium"><i class="icon-chat icon-medium text-white-2"></i></div>
        </div>
        <div class="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">联系电话</div>
        <p class="mx-auto">+49 176 2149 4817</p>
      </div>
      <!-- end contact info item -->
      <!-- start contact info item -->
      <div class="col text-center xs-margin-30px-bottom wow animate__fadeInUp last-paragraph-no-margin" data-wow-delay="0.4s">
        <div class="d-inline-block margin-20px-bottom">
          <div class="bg-active-color  icon-round-medium"><i class="icon-envelope icon-medium text-white-2"></i></div>
        </div>
        <div class="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">E-mail</div>
        <p class="mx-auto"><a href="mailto:service@yiouyuan.com">service[at]yiouyuan.com</a></p>
      </div>
      <!-- end contact info item -->
    </div>
  </div>
</section>
<!-- end contact info section -->
