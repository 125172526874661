import {Component, Output, OnInit, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-yoy-daterangepicker',
  templateUrl: './yoy-daterangepicker.component.html',
  styleUrls: ['./yoy-daterangepicker.component.scss']
})
export class YoyDaterangepickerComponent implements OnInit {
  @Output() setStart = new EventEmitter<string>();
  @Output() setEnd = new EventEmitter<string>();

  now = new Date();

  constructor() { }

  ngOnInit(): void {
  }

  saveStartDate(event: any): void {
    this.setStart.emit(event.value.format('YYYY/MM/DD'));
  }

  saveEndDate(event: any): void {
    this.setEnd.emit(event.value.format('YYYY/MM/DD'));
  }

}
