import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ContactData} from 'src/app/models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = '.';

  constructor(private http: HttpClient) {}

  submitContact(data: ContactData): Observable<any> {
    return this.http.post(`${this.baseUrl}/mail.php`,
      {
        username: data.username,
        email: data.email,
        title: data.title,
        message: data.message
      });
  }
}
