<!-- start hero with parallax section -->
<!-- start video style 04 section -->
<section class="position-relative wow p-0 animate__fadeIn">
  <div class="opacity-medium bg-extra-dark-gray z-index-0"></div>
  <div class="container full-screen position-relative">
    <div class="row justify-content-center h-100">
      <div class="row m-0 h-100 align-items-center">
        <div class="col-12 col-xl-4 col-md-8 offset-xl-4 offset-md-2 text-center padding-six-all lg-padding-eight-all">
          <div class="alt-font text-extra-dark-gray text-uppercase font-weight-700 letter-spacing-minus-2 title-large text-active-color"><img src="assets/images/logo_main.png" data-at2x="assets/images/logo_main@2x.png"></div>
          <span class="text-white text-extra-large font-weight-400 margin-35px-bottom margin-15px-top d-block md-margin-25px-bottom sm-margin-15px-bottom"><span class="text-active-color">缘</span>起于心，<span class="text-active-color">忆</span>留于行。</span>
        </div>
        <div class="down-section text-center">
          <a href="#why" class="section-link up-down-ani"><i class="ti-mouse icon-small bounce text-deep-pink"></i></a>
        </div>
      </div>
    </div>
  </div>
  <!-- start HTML5 video -->
  <img id="home_image" class="htms-video htms-image">
  <video id="home_video" controls playsinline [muted]="true" poster="/assets/images/video-poster.jpg" class="htms-video">
    <source type="video/mp4" src="assets/video/intro.mov">
  </video>
  <!-- end HTML5 video -->
</section>
<!-- end hero banner with parallax section -->

<!-- start about agency -->
<section class="wow animate__fadeIn bg-light-gray">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-8 col-lg-9 col-md-11 text-center wow animate__fadeInUp margin-eight-bottom">
        <h6 class="font-weight-300 text-extra-dark-gray mb-0">沉浸于璀璨文明的古城，领略大自然的壮丽风光。欧洲之美等您发现，快来预订您的欧洲之行，开启一段令人难忘的欧洲探索之旅！</h6>
      </div>
    </div>
    <div class="row justify-content-center align-items-center">
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInUp">
        <div class="bg-white text-center">
          <a (click)="this.linkToSchedule.emit('fr')"><img data-no-retina src="assets/images/classic_1.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">法兰西魅影，奢逸之旅</span>
            <p class="text-justify">走过灯火阑珊的香榭丽舍大道，从巴黎浪漫的埃菲尔铁塔到普罗旺斯的花海，你的每一步都散发着法国独特的浪漫气息。品味美食、欣赏艺术、享受奢华住宿，与法国的浪漫，优雅与精致相遇。让我们为您打造定制化的旅程，让您的法国之旅成为一段令人心驰神往的浪漫之旅</p>
            <a (click)="this.linkToSchedule.emit('fr')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 md-margin-30px-bottom wow animate__fadeInUp" data-wow-delay="0.2s">
        <div class="bg-white text-center">
          <a (click)="this.linkToSchedule.emit('it')"><img data-no-retina src="assets/images/classic_2.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">品味意大利，尊贵之旅</span>
            <p class="text-justify">从威尼斯的浪漫水城到罗马的古老遗迹，每一步都沉浸在悠久历史和文化的氛围中。漫步托斯卡纳的美丽乡村，品味世界顶级美食，享受五星级奢华住宿，让您的意大利之旅充满独特的魅力。让我们为您量身打造定制化的旅程，让您的意大利之梦成为一段卓越而令人难忘的文艺之旅</p>
            <a (click)="this.linkToSchedule.emit('it')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 wow animate__fadeInUp" data-wow-delay="0.4s">
        <div class="bg-white text-center">
          <a (click)="this.linkToUrl.emit('schedule')"><img data-no-retina src="assets/images/classic_3.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">德国童话，独特之选</span>
            <p class="text-justify">从梦幻的巴伐利亚城堡到令人惊叹的阿尔卑斯山脉，每一步都沉浸在自然奇观和历史传承之中。探索德国的文化瑰宝，漫步在神秘而美丽的黑森林，品味葡萄酒之都巴登的美食，感受德国之魅力。让我们为您打造精心定制的旅程，让您的德国之旅成为一段令人难忘的童话之旅</p>
            <a (click)="this.linkToUrl.emit('schedule')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
    </div>
  </div>
</section>
<!-- end about agency section -->

<!-- start counter section -->
<section class="wow animate__fadeIn">
  <div class="container">
    <div class="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4">
      <!-- counter box item -->
      <div class="col md-margin-60px-bottom md-padding-ten-left sm-margin-40px-bottom sm-padding-15px-left wow animate__fadeInDown">
        <i class="icon-happy icon-medium float-start text-active-color"></i>
        <div class="float-start padding-nine-left">
          <h5 class="text-extra-dark-gray font-weight-300 margin-three-bottom counter" data-to="120" data-speed="2000">650</h5>
          <span class="text-medium-small text-medium-gray text-uppercase d-block">客户好评</span>
          <div class="separator-line-horrizontal-medium-light2 bg-deep-pink d-inline-block"></div>
        </div>
      </div>
      <!-- end counter box item -->
      <!-- counter box item -->
      <div class="col md-margin-60px-bottom md-padding-ten-left sm-margin-40px-bottom sm-padding-15px-left wow animate__fadeInDown" data-wow-delay="0.2s">
        <i class="icon-layers icon-medium float-start text-active-color"></i>
        <div class="float-start padding-nine-left">
          <h5 class="text-extra-dark-gray font-weight-300 margin-three-bottom counter" data-to="387" data-speed="2000">667</h5>
          <span class="text-medium-small text-medium-gray text-uppercase d-block">完成项目</span>
          <div class="separator-line-horrizontal-medium-light2 bg-deep-pink d-inline-block"></div>
        </div>
      </div>
      <!-- end counter box item -->
      <!-- counter box item -->
      <div class="col md-padding-ten-left sm-padding-15px-left xs-margin-40px-bottom wow animate__fadeInDown" data-wow-delay="0.4s">
        <i class="icon-global icon-medium float-start text-active-color"></i>
        <div class="float-start padding-nine-left">
          <h5 class="text-extra-dark-gray font-weight-300 margin-three-bottom counter" data-to="30" data-speed="2000">350</h5>
          <span class="text-medium-small text-medium-gray text-uppercase d-block">屡获殊荣</span>
          <div class="separator-line-horrizontal-medium-light2 bg-deep-pink d-inline-block"></div>
        </div>
      </div>
      <!-- end counter box item -->
      <!-- counter box item -->
      <div class="col md-padding-ten-left sm-padding-15px-left wow animate__fadeInDown" data-wow-delay="0.6s">
        <i class="icon-calendar icon-medium float-start text-active-color"></i>
        <div class="float-start padding-nine-left">
          <h5 class="text-extra-dark-gray font-weight-300 margin-three-bottom counter" data-to="2010" data-speed="2000">12</h5>
          <span class="text-medium-small text-medium-gray text-uppercase d-block">传统老店</span>
          <div class="separator-line-horrizontal-medium-light2 bg-deep-pink d-inline-block"></div>
        </div>
      </div>
      <!-- end counter box item -->
    </div>
  </div>
</section>
<!-- end counter section -->

<!-- start slider section -->
<section class="wow animate__fadeIn p-0">
  <div class="swiper-auto-height-container swiper-container position-relative" data-slider-options='{"loop": true, "effect":"fade", "autoHeight":true, "allowTouchMove":true, "autoplay": { "delay": 3000 }, "navigation": { "nextEl": ".swiper-button-next", "prevEl": ".swiper-button-prev" }, "pagination": { "el":".swiper-pagination-01", "clickable": true } }'>
    <div class="swiper-wrapper">
      <!-- start slider item -->
      <div class="swiper-slide padding-100px-all sm-padding-20px-all cover-background" style="background-image:url('assets/images/main_01.jpg')">
        <div class="position-relative w-40 xl-w-45 lg-w-60 md-w-85 sm-w-100 d-inline-block slide-banner last-paragraph-no-margin">
          <div class="padding-80px-all lg-padding-65px-all md-padding-40px-all sm-w-100 bg-black-opacity sm-padding-fifteen-tb sm-padding-30px-all text-center text-md-start">
            <h5 class="alt-font text-white-2 w-90 md-w-100">私人订制</h5>
            <p class="w-90 lg-w-100 text-medium-gray">我们与欧洲顶级的酒店、航空公司、私人导游和豪华车辆合作，为您提供卓越的住宿、舒适的交通和个性化的导览。无论是浪漫的葡萄酒之旅、壮丽的阿尔卑斯山探险，还是文化底蕴深厚的古城之旅，我们将超越期待，为您开启全新视野。选择我们，尽享私人订制之旅，让每次旅行都成为一场无与伦比的华丽体验，留下令人难忘的回忆。</p>
            <a (click)="this.linkToUrl.emit('custom')" class="margin-35px-top md-margin-15px-top btn btn-small btn-white">了解详情</a>
          </div>
        </div>
      </div>
      <!-- end slider item -->
      <!-- start slider item -->
      <div class="swiper-slide padding-100px-all sm-padding-20px-all cover-background" style="background-image:url('assets/images/main_02.jpg')">
        <div class="position-relative w-40 xl-w-45 lg-w-60 md-w-85 sm-w-100 d-inline-block slide-banner last-paragraph-no-margin">
          <div class="padding-80px-all lg-padding-65px-all md-padding-40px-all bg-black-opacity sm-padding-fifteen-tb sm-padding-30px-all text-center text-md-start">
            <h5 class="alt-font text-white-2 w-90 md-w-100">欧洲游学</h5>
            <p class="w-90 lg-w-100 text-medium-gray">开启智慧之门，欧洲游学之旅！与我们游学，探索世界名校，感受浓厚文化。步入牛津剑桥，巴黎索邦，慕尼黑工大等学府，与顶尖导师互动交流，燃起智慧的火花。沉浸学科课程、文化考察和实践活动，锻炼综合能力。专业团队全程陪同，安全舒适保障。让孩子的梦想起飞，拓展未来之路。选择我们，开启独一无二的学术探索，铸就辉煌人生！</p>
            <a (click)="this.linkToUrl.emit('study')" class="margin-35px-top md-margin-15px-top btn btn-small btn-white">了解详情</a>
          </div>
        </div>
      </div>
      <!-- end slider item -->
      <!-- start slider item -->
      <div class="swiper-slide padding-100px-all sm-padding-20px-all cover-background" style="background-image:url('assets/images/main_03.jpg')">
        <div class="position-relative w-40 xl-w-45 lg-w-60 md-w-85 sm-w-100 d-inline-block slide-banner last-paragraph-no-margin">
          <div class="padding-80px-all lg-padding-65px-all md-padding-40px-all bg-black-opacity sm-padding-fifteen-tb sm-padding-30px-all text-center text-md-start">
            <h5 class="alt-font text-white-2 w-90 md-w-100">商务考察</h5>
            <p class="w-90 lg-w-100 text-medium-gray">凭借多年积累的资源和经验，我们建立了稳定可靠的合作网络，为客户提供高质量的商务考察体验。我们主办各类商务活动，接待来自不同领域的代表团，为他们提供精心策划的行程、专业翻译、商务洽谈支持等一揽子服务。我们的目标是为客户搭建沟通桥梁，拓展商机，加强合作，实现共赢发展。让我们携手，开启成功之旅，共创辉煌商业未来！</p>
            <a (click)="this.linkToUrl.emit('business')" class="margin-35px-top md-margin-15px-top btn btn-small btn-white">了解详情</a>
          </div>
        </div>
      </div>
      <!-- end slider item -->
    </div>
    <!-- start slider pagination -->
    <!-- <div class="swiper-pagination swiper-pagination-01 swiper-pagination-white"></div> -->
    <!-- end slider pagination -->
    <!-- start slider navigation -->
    <div class="navigation-area">
      <div class="swiper-button-next swiper-next-style4"><i class="fas fa-arrow-up" aria-hidden="true"></i></div>
      <div class="swiper-button-prev swiper-prev-style4"><i class="fas fa-arrow-down" aria-hidden="true"></i></div>
    </div>
    <!-- end slider navigation -->
  </div>
</section>
<!-- end slider section -->

<!-- start blog section -->
<section id="blog" class="bg-light-gray blog-post-style5 wow animate__fadeIn">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center margin-100px-bottom md-margin-70px-bottom sm-margin-50px-bottom">
        <h5 class="text-uppercase alt-font text-extra-dark-gray margin-20px-bottom font-weight-700 md-w-100">新闻资讯</h5>
        <span class="separator-line-horrizontal-medium-light2 bg-deep-pink d-table mx-auto w-100px"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 blog-content">
        <ul class="blog-masonry blog-wrapper grid grid-loading grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col blog-post-style5 gutter-large">
          <li class="grid-sizer"></li>
          <!-- start post item -->
          <li *ngFor="let item of DataJson | slice:0:3;" class="grid-item last-paragraph-no-margin wow animate__fadeInUp">
            <div class="blog-post">
              <div class="blog-post-images overflow-hidden">
                <a (click)="this.linkToBlog.emit(item.id)">
                  <img data-no-retina src="assets/images/blog_{{item.id}}.jpg" alt="">
                </a>
                <div class="blog-categories bg-white text-uppercase text-extra-small alt-font"><a (click)="this.linkToBlog.emit(item.id)">{{item.category_txt}}</a></div>
              </div>
              <div class="post-details padding-35px-all bg-white md-padding-20px-all">
                <div class="blog-hover-color"></div>
                <a (click)="this.linkToBlog.emit(item.id)" class="alt-font post-title text-medium text-extra-dark-gray w-90 d-block lg-w-100 margin-5px-bottom">{{item.header}}</a>
                <div class="author">
                  <span class="text-medium-gray text-uppercase text-extra-small d-inline-block">{{item.date}}</span>
                </div>
                <div class="separator-line-horrizontal-full bg-medium-gray margin-seven-tb lg-margin-four-tb"></div>
                <p>{{item.title}}...</p>
              </div>
            </div>
          </li>
          <!-- end post item -->
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- end blog section -->

<!-- start about us section -->
<section class="wow animate__fadeIn md-no-padding-bottom sm-padding-50px-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-xl-6 col-xxl-5 margin-eight-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center">
        <h5 class="alt-font font-weight-700 text-extra-dark-gray margin-20px-bottom text-uppercase">关于我们</h5>
        <span class="separator-line-horrizontal-medium-light2 bg-deep-pink d-table mx-auto w-100px"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-3 col-sm-6 text-center ps-0 lg-padding-15px-left md-no-padding wow animate__fadeIn">
        <img data-no-retina src="assets/images/aboutus_1.jpg" alt="" class="w-100">
      </div>
      <div class="col-12 col-xl-3 col-sm-6 text-center ps-0 lg-padding-15px-left md-no-padding wow animate__fadeIn" data-wow-delay="0.2s">
        <!--<img src="https://via.placeholder.com/600x909" alt="" class="w-100">-->
        <img data-no-retina src="assets/images/aboutus_2.jpg" alt="" class="w-100">
      </div>
      <div class="col-12 col-xl-6 ps-0 lg-margin-15px-top md-no-margin-top md-no-padding-right wow animate__fadeIn" data-wow-delay="0.4s">
        <div class="d-flex flex-column align-content-center justify-content-center bg-extra-dark-gray padding-twelve-all md-padding-ten-all text-center text-lg-start sm-padding-five-lr sm-padding-fifteen-tb w-100 h-100">
          <p class="text-extra-large text-medium-gray font-weight-300">我们以独一无二的服务理念和精心打造的旅程，为您提供一流的定制旅游、游学和商务接待服务</p>
          <p class="text-medium-small">忆欧缘是<a class="text-active-color" href="https://www.kewei-group.com" target="_blank">可为集团</a>旗下的一家成立于2011年的德国高端私人定制旅游品牌，总部位于美丽的莱茵湖畔法兰克福，专注于为客户提供个性化的旅游、游学和商务接待服务。我们致力于打造独一无二的旅行体验，让客人深度感受德国和欧洲的文化、历史和风情。</p>
          <a (click)="this.linkToUrl.emit('contact')" class="text-uppercase alt-font text-white-2 text-medium">联系我们 <i class="fas fa-long-arrow-alt-right margin-5px-left text-deep-pink text-medium position-relative top-2" aria-hidden="true"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end about us section -->


