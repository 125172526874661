import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChange, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Option } from 'src/app/models/components';
import { Subscription } from 'rxjs';
import { Room } from 'src/app/models/index';
import { faMale, faBaby } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-yoy-room-select',
  templateUrl: './yoy-room-select.component.html',
  styleUrls: ['./yoy-room-select.component.scss'],
  styles: [':host { font-size: .875em; }']
})
export class YoyRoomSelectComponent implements OnInit, OnChanges {

  @Input() label: string;
  @Input() rooms: Room[] = null;
  @Input() withall = false;

  @Output()
  changeSetting: EventEmitter<string | number> = new EventEmitter<string | number>();

  listVisible = false;

  constructor( public dialog: MatDialog ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {

  }

  getRommsInfo(): string {
    if (this.rooms && this.rooms.length > 0) {
      let adults = 0;
      let kids = 0;
      this.rooms.map((x) => {
        adults = adults + x.adults;
        kids = kids + x.kids;
      });
      return adults + '位成人 ' + kids + '个小孩 ' + this.rooms.length.toString() + '间房';
    }

    return '0位成人 0个小孩 0间房';
  }

  openDialog(clickTarget: any = null): void {
    const config   = {
      data: {
        title:    '选择人数和房间',
        label:    'label',
        name:     'name',
        rooms:    this.rooms
      }
    };

    const dialogRef = this.dialog.open(YoyRoomSelectDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}

@Component({
  selector: 'app-yoy-room-select-dialog',
  templateUrl: './yoy-room-select-dialog.html',
  styleUrls: ['./yoy-room-select.component.scss']
})
export class YoyRoomSelectDialogComponent implements OnInit, OnDestroy{

  value = 1;
  countAdult = 1;
  countKids = 0;
  rooms: Room[];

  faMale = faMale;
  faBaby = faBaby;

  constructor(public dialogRef: MatDialogRef<YoyRoomSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.rooms = data.rooms;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addRoom(id: number): void {
    this.rooms.push({
      id,
      adults: 1,
      kids: 0
    });
  }

  removeRoom(id: number): void {
    this.rooms = this.rooms.filter((x) => x.id !== id);
    this.rooms.map((x, index) => {
      x.id = index + 1;
    });
  }
}
