import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { AbstractContentComponent } from '../abstract.component';
import {DataFactory, Response, Option, Country, Room, ResponseMsg} from 'src/app/models/index';
import {ContactService, DatabaseService} from 'src/app/services/index';
import { ReCaptcha2Component } from 'ngx-captcha';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomComponent extends AbstractContentComponent implements OnInit {
  aFormGroup: FormGroup;
  df: DataFactory = { data: [] };
  countries: Country[] = [];
  rooms: Room[] = [{
    id: 1,
    adults: 1,
    kids: 0
  }];
  requiresDate = {
    start: '',
    end: ''
  };
  visibleList = ['type', 'star', 'food', 'transport', 'budget', 'schedule', 'photograph'];

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(
    public formBuilder: FormBuilder,
    public dataService: DatabaseService,
    public contactService: ContactService,
    private snackBar: MatSnackBar) {
    super();
  }

  ngOnInit(): void {
    this.initialLayout();
    const db = this.dataService.getListByName('/custom').valueChanges();
    db.subscribe(items => {
      items.map(item => {
        this.initialOptions(item.name, item.data);
      });
    });
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      info: [''],
      pickup: [false],
      phone: [''],
    });
  }

  initialOptions(key: string, val: Response[]): void {
    if (key === 'countries') {
      val?.map(x => {
        this.countries.push({
          name: x.name,
          role: x.role,
          country: x.country,
          code: x.code,
          label: x.label,
          selected: false,
          open: false,
          visible: (x.role === 'country') ? true : false
        });
      });
    }

    if (this.visibleList.includes(key)) {
      const item = {
        name: key,
        label: key,
        visible: true,
        type: 'dropdown',
        data: []
      };

      val?.map(x => {
        item.data.push({
          id: x.id,
          value: x.value,
          label: x.label,
          selected: (key === 'type' && this.type === x.value) ? true : false
        });
      });

      this.df.data.push(item);
    }
  }

  get username(): AbstractControl {
    return this.aFormGroup.get('username');
  }

  get email(): AbstractControl {
    return this.aFormGroup.get('email');
  }

  get phone(): AbstractControl {
    return this.aFormGroup.get('phone');
  }

  get info(): AbstractControl {
    return this.aFormGroup.get('info');
  }

  get pickup(): AbstractControl {
    return this.aFormGroup.get('pickup');
  }

  getOption(name: string): Option[] {
    return this.df?.data.find((x) => x.name === name)?.data;
  }

  send(): void {
    const requires: string[] = [];
    const requiresCountry: string[] = [];
    const requiresRoom: string[] = [];
    this.df.data.map(item => {
      item.data.map((option) => {
        if (option.selected) {
          requires.push(option.label);
        }
      });
    });

    this.countries.map(country => {
      if (country.selected) {
        requiresCountry.push(country.label);
      }
    });
    if (requiresCountry.length > 0) {
      requires.push('目的地:' + requiresCountry.join(','));
    }

    this.rooms.map(room => {
      requiresRoom.push( '[房间#' + room.id.toString() + ': ' + room.adults + '个大人' + room.kids + '个小孩]' );
    });
    if (requiresRoom.length > 0) {
      requires.push('房间:' + requiresRoom.join(' '));
    }

    if (this.requiresDate.start !== '' || this.requiresDate.end !== '') {
      requires.push('日期:' + this.requiresDate.start + ' - ' + this.requiresDate.end);
    }

    requires.push('姓名:' + this.aFormGroup.get('username').value);
    requires.push('手机:' + this.aFormGroup.get('phone').value);
    requires.push('邮箱:' + this.aFormGroup.get('email').value);
    requires.push('备注:' + this.aFormGroup.get('info').value);
    requires.push('接送机:' + ((this.aFormGroup.get('pickup').value) ? '需要' : '不需要'));

    console.log(requires);

    const sendContent = {
      email: this.aFormGroup.get('email').value,
      title: '来自私人订制的订单',
      message: requires.join('\r\n'),
      username: this.aFormGroup.get('username').value
    };

    this.contactService.submitContact(sendContent).subscribe((res: ResponseMsg) => {
      this.captchaElem.reloadCaptcha();
      this.aFormGroup.setValue({
        recaptcha: '',
        username: '',
        phone: '',
        email: '',
        info: '',
        pickup: false
      });
      if (res.status === 1) {
        this.snackBar.open(res.message, 'close', {duration: 5000});
      } else {
        this.snackBar.open(res.errors.join(';'), 'close', {duration: 5000});
      }
    });


  }

}
