<!-- start page title section -->
<section class="wow animate__fadeIn parallax padding-55px-tb" data-parallax-background-ratio="0.5" style="background-image:url('assets/images/main_02.jpg');">
  <div class="opacity-medium bg-extra-dark-gray"></div>
  <div class="container position-relative">
    <div class="row align-items-center">
      <div class="col-12 d-flex flex-column justify-content-center text-center extra-small-screen page-title-large">
        <!-- start page title -->
        <h1 class="text-white-2 alt-font font-weight-600 letter-spacing-minus-1 margin-10px-bottom">欧洲游学</h1>
        <!-- end page title -->
        <!-- start sub title -->
        <span class="text-white-2 opacity6 alt-font">双元制教育，扬帆知行，绽放个人潜能</span>
        <!-- end sub title -->
      </div>
    </div>
  </div>
</section>
<!-- end page title section -->

<section id="main" class="padding-65px-top">
  <div class="section" style="overflow: unset">
    <div class="container" style="text-align: justify">
      欧洲就像一本立体的教科书，处处可见的世界文化遗产、百年历史名校、各种博物馆、科技馆，将欧洲历史生动地展现在世人面前。游学走进欧洲，走向世界，带您的孩子畅享历史文化盛宴，真正体验西方文化带给内心的那份震撼。<br><br>

      欧洲游学，收获的不仅是文化，更加重要的是提升孩子的个人素养。加上身临其境的语言环境，更好地促使孩子们仔细听、开口说，语言水平也在不知不觉中得以提升。同时我们的很多欧洲游学线路也会安排孩子们与国际学生一起上课，入住当地寄宿家庭或学生公寓，深入欧洲各国的名校交流和考察。这些都是孩子们对留学真实生活的提前体验，可以很好地帮助您的孩子避免盲目留学带来的痛苦。<br><br>

      “游学”既不是单纯的旅游也不是纯粹的留学，它的内容贯穿了语言学习和参观游览，介于游与学之间，同时又融合了学与游的内容。参加游学的孩子年龄一般介于10岁到18岁之间，根据不同游学项目，年龄要求亦有所变化。因为游学有别于留学， 所以对语言没有硬性要求。 我们的游学项目是为了更早更好地帮助您和您的孩子了解感兴趣的国家，为您的孩子将来留学提前做好准备。<br><br>

      如果您有任何相关问题，请随时<a href="#" (click)="this.linkToUrl.emit('contact')">联系我们</a>。
    </div>
  </div>
</section>
