<!-- start page title section -->
<section class="wow animate__fadeIn parallax padding-55px-tb" data-parallax-background-ratio="0.5" style="background-image:url('assets/images/main_01.jpg');">
  <div class="opacity-medium bg-extra-dark-gray"></div>
  <div class="container position-relative">
    <div class="row align-items-center">
      <div class="col-12 d-flex flex-column justify-content-center text-center extra-small-screen page-title-large">
        <!-- start page title -->
        <h1 class="text-white-2 alt-font font-weight-600 letter-spacing-minus-1 margin-10px-bottom">私人订制</h1>
        <!-- end page title -->
        <!-- start sub title -->
        <span class="text-white-2 opacity6 alt-font">私享尊荣，个性定制之旅</span>
        <!-- end sub title -->
      </div>
    </div>
  </div>
</section>
<!-- end page title section -->
<!-- start about agency -->
<section class="wow animate__fadeIn bg-light-gray">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 xl-margin-15px-bottom margin-30px-bottom md-margin-30px-bottom wow animate__fadeInUp">
        <div class="bg-white text-center">
          <a (click)="this.linkToSchedule.emit('fr')"><img src="assets/images/classic_1.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">法兰西魅影，奢逸之旅</span>
            <p class="text-justify">13天12晚，巴黎-普罗旺斯-蓝色海岸</p>
            <a (click)="this.linkToSchedule.emit('fr')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 xl-margin-15px-bottom margin-30px-bottom md-margin-30px-bottom wow animate__fadeInUp" data-wow-delay="0.2s">
        <div class="bg-white text-center">
          <a (click)="this.linkToSchedule.emit('it')"><img src="assets/images/classic_2.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">品味意大利，尊贵之旅</span>
            <p class="text-justify">13天12晚，罗马-佛罗伦萨-威尼斯</p>
            <a (click)="this.linkToSchedule.emit('it')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 xl-margin-15px-bottom margin-30px-bottom md-margin-30px-bottom wow animate__fadeInUp" data-wow-delay="0.4s">
        <div class="bg-white text-center">
          <a (click)="this.linkToSchedule.emit('de')"><img src="assets/images/classic_3.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">德国童话，独特之选</span>
            <p class="text-justify">9天8晚，慕尼黑-黑森林-天鹅堡</p>
            <a (click)="this.linkToSchedule.emit('de')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
      <!-- start features box item -->
      <div class="col-12 col-lg-4 col-md-6 col-sm-8 wow animate__fadeInUp" data-wow-delay="0.2s">
        <div class="bg-white text-center">
          <a (click)="this.linkToSchedule.emit('football')"><img src="assets/images/classic_4.jpg" alt=""></a>
          <div class="padding-45px-all md-padding-30px-all">
            <span class="text-extra-dark-gray font-weight-600 d-block alt-font margin-10px-bottom">欧洲足球朝圣之旅</span>
            <p class="text-justify">12天11晚，欧洲4大联赛8支豪门</p>
            <a (click)="this.linkToSchedule.emit('football')" class="btn btn-small btn-rounded btn-transparent-deep-pink">了解详情 <i class="ti-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!-- end features box item -->
    </div>
  </div>
</section>
<!-- end about agency section -->

<section id="main" class="padding-65px-top">
  <div class="section" style="overflow: unset">
    <div class="container">
      <form [formGroup]="aFormGroup">
      <div class="row">
        <div class="column col-lg-4">
          <app-yoy-daterangepicker (setStart)="requiresDate.start = $event" (setEnd)="requiresDate.end = $event"></app-yoy-daterangepicker>
        </div>
      </div>
      <div class="row">
        <div class="column col-lg-4">
          <app-yoy-dropdown [options]="getOption('type')"
                            [icon]="'list'"
                            [label]="'类别'"></app-yoy-dropdown>
        </div>
        <div class="column col-lg-4">
          <app-yoy-country-select [options]="countries"
                                  [label]="'目的地(多选)'"></app-yoy-country-select>
        </div>
        <div class="column col-lg-4">
          <app-yoy-room-select [rooms]="rooms"
                               [label]="'房间'"></app-yoy-room-select>
        </div>
      </div>
      <div class="row">
        <div class="column col-lg-4">
          <app-yoy-dropdown [withall]="true"
                            [icon]="'hotel_class'"
                            [options]="getOption('star')"
                            [label]="'住宿规格'"></app-yoy-dropdown>
        </div>
        <div class="column col-lg-4">
          <app-yoy-dropdown [withall]="true"
                            [icon]="'local_dining'"
                            [options]="getOption('food')"
                            [label]="'餐饮种类'"></app-yoy-dropdown>
        </div>
        <div class="column col-lg-4">
          <app-yoy-dropdown [withall]="true"
                            [icon]="'drive_eta'"
                            [options]="getOption('transport')"
                            [label]="'车型要求'"></app-yoy-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="column col-lg-4">
          <app-yoy-dropdown [withall]="true"
                            [icon]="'euro_symbol'"
                            [options]="getOption('budget')"
                            [label]="'人均预算'"></app-yoy-dropdown>
        </div>
        <div class="column col-lg-4">
          <app-yoy-dropdown [withall]="true"
                            [icon]="'schedule'"
                            [options]="getOption('schedule')"
                            [label]="'行程安排'"></app-yoy-dropdown>
        </div>
        <div class="column col-lg-4">
          <app-yoy-dropdown [withall]="true"
                            [icon]="'video_camera_front'"
                            [options]="getOption('photograph')"
                            [label]="'摄影跟拍'"></app-yoy-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="column col-lg-4 input-row">
          <label class="label-block"><i class="material-icons">person</i>姓名*</label>
          <input name="input" type="text" formControlName="username" required>
          <div class="form-group-error" *ngIf="!username?.valid && (username?.dirty ||username?.touched)">
            <div class="text-small text-active-color" [hidden]="!username.errors.required">
              * 请填写姓名
            </div>
          </div>
        </div>
        <div class="column col-lg-4 input-row">
          <label class="label-block"><i class="material-icons">smartphone</i>手机</label>
          <input name="input" type="text" formControlName="phone">
        </div>
        <div class="column col-lg-4 input-row">
          <label class="label-block"><i class="material-icons">email</i>邮箱*</label>
          <input name="input" type="text" formControlName="email" required>
          <div class="form-group-error" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">
            <div class="text-small text-active-color" [hidden]="!email.errors.required">
              * 请填写邮箱
            </div>
            <div class="text-small text-active-color" [hidden]="!email.errors.email">
              * 请填写有效的邮箱地址
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column col-lg-2">
          <mat-slide-toggle formControlName="pickup">接送机</mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="column col-lg-12 input-row">
          <label class="label-block"><i class="material-icons">lightbulb</i>备注</label>
          <textarea name="info" rows="4" formControlName="info"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mb-1 text-right" style="display: flex;flex-direction: row">
          <div style="flex: 1 1 auto"></div>
          <ngx-recaptcha2 #captchaElem
                          required
                          siteKey="6Lf9Mo4iAAAAAAZL_oBw0X8pc77zia4AD9dhkx2j"
                          [type]="'image'"
                          [useGlobalDomain]="false"
                          formControlName="recaptcha">
          </ngx-recaptcha2>
        </div>
      </div>
      <div class="row justify-content-center submit-row">
        <div class="column col-lg-12 btn btn-medium btn-deep-pink lg-margin-15px-bottom d-table d-lg-inline-block md-margin-lr-auto">
          <button [disabled]="aFormGroup.invalid" type="submit" class="btn" (click)="send()">提交</button>
        </div>
      </div>
      </form>
    </div>
  </div><!--/.section-->
</section><!--/#main -->
