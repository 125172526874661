import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../../models/index';

@Pipe({
  name: 'yoySelectFilterPipe',
  pure: false
})
export class YoySelectFilterPipePipe implements PipeTransform {

  transform(items: Country[], field: string, filter: any): Country[] {
    if (!items || !filter){
      return items;
    }
    return items.filter((item: Country) => this.applyFilter(item, field, filter));
  }

  /**
   * Perform the filtering.
   *
   * @param  {Option} option The option to compare to the filter.
   * @param  {String} field The filter to apply.
   * @return {boolean} True if option satisfies filters, false if not.
   */
  applyFilter(option: Country, field: string, filter: any): boolean {
    if (field !== '') {
      if (typeof option[field] === 'string' ) {
        if (option[field].toLowerCase().includes(filter.toLowerCase()) === false) {
          return false;
        }
      } else if (typeof option[field] === 'number' || typeof option[field] === 'boolean') {
        if (option[field] !== filter) {
          return false;
        }
      }

    }
    return true;
  }

}
