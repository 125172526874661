import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// declare the javascript function here
declare function closeNav(): void;
@Component({
  templateUrl: './abstract.component.html'
})



export class AbstractContentComponent implements OnInit {

  @Input() type: number;
  @Input() name: string;
  @Output()
  linkToUrl: EventEmitter<string | number> = new EventEmitter<string>();

  @Output()
  linkToSchedule: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  linkToBlog: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  initialLayout(): void {
    closeNav();
    window.scroll(0, 0);
  }

}
