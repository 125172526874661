import { Component, OnInit } from '@angular/core';

import {Slide, Button, Blog, DwhApi} from 'src/app/models/index';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';

import { DatabaseService, LoadingService } from 'src/app/services/index';

// @ts-ignore
import { AbstractContentComponent } from '../abstract.component';

import {HttpClient} from "@angular/common/http";

// declare the javascript function here
declare function setupSwiper(): void;
declare function loadPage(): void;
declare function setupVideo(): void;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends AbstractContentComponent implements OnInit {

  url = '';
  DataJson: Blog[];
  mainSlideData: Slide[];
  projectSlideData: Slide[];
  teamSlideData: Slide[];
  businessSlideData: Slide[];

  constructor(public dataService: DatabaseService, public  loadingService: LoadingService, private http: HttpClient) {
    super();
  }

  ngOnInit(): void {
    this.initialLayout();
    setupVideo();
    this.url = "/assets/data/data.json";
    this.http.get(this.url).subscribe((res: DwhApi) => {
      this.DataJson = res.business;
      this.loadingService.showLoading(false);
      setTimeout(() => {
        setupSwiper();
        loadPage();
      })
    });
  }

  initialSlider(key: string, val: Slide[]): void {
    /*
    if (key === 'main') {
      this.mainSlideData = val;
    }

    if (key === 'project') {
      this.projectSlideData = val;
    }

    if (key === 'team') {
      this.teamSlideData = val;
    }

    if (key === 'business') {
      this.businessSlideData = val;
    }
    */
  }

}
