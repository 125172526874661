<!-- start page title section -->
<section class="p-0 one-third-screen position-relative wow animate__fadeIn">
  <div class="opacity-medium bg-extra-dark-gray z-index-2"></div>
  <div class="container position-relative z-index-2">
    <div class="row">
      <div class="col-12 one-third-screen page-title-large d-flex flex-column justify-content-center text-center">
        <!-- start sub title -->
        <span class="d-block text-white-2 opacity6 margin-10px-bottom alt-font">{{DataJson?.subtitle}}</span>
        <!-- end sub title -->
        <!-- start page title -->
        <h1 class="alt-font text-white-2 font-weight-600 w-55 sm-w-80 sm-w-100 mx-auto mb-0">{{DataJson?.title}}</h1>
        <!-- end page title -->
      </div>
    </div>
  </div>
  <div class="swiper-auto-fade swiper-container z-index-minus2 position-absolute top-0 w-100 h-100" data-slider-options='{ "loop": true, "effect":"fade", "slidesPerView": "1", "allowTouchMove":true, "autoplay": { "delay": 3000, "disableOnInteraction": false }, "keyboard": { "enabled": true, "onlyInViewport": true }, "navigation": { "nextEl": ".swiper-button-next", "prevEl": ".swiper-button-prev" }, "pagination": { "el": ".swiper-pagination", "clickable": true } }'>
    <div class="swiper-wrapper">
      <!-- start slider item -->
      <div *ngFor="let image of DataJson?.images" class="swiper-slide cover-background one-third-screen" [style.background-image]="'url(' + image + ')'"></div>
      <!-- end slider item -->
    </div>
    <!-- <div class="swiper-pagination swiper-auto-pagination"></div> -->
  </div>
</section>
<!-- end page title section -->
<div class="price-panel">
  <span class="price" (click)="this.linkToUrl.emit('contact')">
     <span>{{DataJson?.price}}</span>/人起
     <i class="fa fa-envelope"></i>
  </span>
</div>

<section id="main" class="no-padding-bottom">
  <div id="subnav" class="section">
    <div class="container">
      <nav class="sub-nav">
        <ul class="sub-nav-ul">
          <li><a href="#ideas">设计思路</a></li>
          <li><a href="#plan">行程方案</a></li>
          <li><a href="#price">价格内容</a></li>
        </ul>
        <div class="sub-nav-price">
          <span class="price">
            <span>{{DataJson?.price}}</span>/人起
          </span>
          <span class="request" (click)="this.linkToUrl.emit('contact')">免费咨询</span>
        </div>
      </nav>
    </div>
  </div>
  <div id="ideas" class="section">
    <div class="container">
      <mat-toolbar class="ideas-toolbar">
        <i class="material-icons">emoji_objects</i><span>设计思路</span>
        <span class="toolbar-spacer"></span>
        <i class="material-icons">star</i>
        <i class="material-icons">place</i>
        <i class="material-icons">restaurant_menu</i>
        <i class="material-icons">hotel</i>
      </mat-toolbar>
      <div class="ideas-container">
        <div class="why">
          <h6>为什么去这儿</h6>
          <ul>
            <li *ngFor="let item of DataJson?.why">{{item}}</li>
          </ul>
        </div>
        <div class="play">
          <h6>玩什么</h6>
          <ul>
            <li *ngFor="let item of DataJson?.play">{{item}}</li>
          </ul>
        </div>
        <div class="eat">
          <h6>吃什么</h6>
          <ul>
            <li *ngFor="let item of DataJson?.eat">{{item}}</li>
          </ul>
        </div>
        <div class="sleep">
          <h6>住哪里</h6>
          <ul>
            <li>精选推荐当地网红民宿或4星级以上旅馆。</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="plan" class="section" style="overflow: unset">
    <div class="container schedule-container">
      <mat-accordion [displayMode]="'flat'" [multi]="true">
        <mat-expansion-panel [expanded]="true" *ngFor="let item of DataJson?.plan">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D{{item.day}}</div>
              <span>{{item.title}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons" *ngFor="let type of item?.categories">{{type}}</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily" *ngFor="let todo of item?.data">
              <button mat-mini-fab>
                <mat-icon>{{todo?.category}}</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>{{todo?.content}}</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <!--
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D2</div>
              <span>巴黎 品味艺术</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">directions_bus</i>
              <i class="material-icons">museum</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>directions_bus</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>早上到达巴黎，专人专车在机场迎接</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>上午参观私人博物馆。这里常被人拿来和位于纽约的弗里克私人美术馆相提并论，因为两者都收藏着大量稀有且价值连城的艺术品。这里也是巴黎最豪华的私人博物馆。里面一切是那么的有格调，庭院里是翠绿的植物加白色的花，房子里的装修考究而高雅。</p>
                <p>和法国厨师学习制作法国甜点马卡龙。马卡龙被誉为法式甜点的代表。它的由来可追溯至19世纪的蛋白杏仁饼，品质顶级的马卡龙，各项材料、口味的协调、对比极为讲究，同时融合各种味觉，产生丰富的层次。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>您可以在其充满洛可可风格的私家餐厅，享用法式皇家情调的午餐</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D3</div>
              <span>巴黎  宝石世界</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">storefront</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>您将投身到宝石的奇妙世界。在全球知名的宝石学校上一堂宝石解读课程，在一段精彩绝伦的旅程中探索宝石从诞生、开采到切割的整个过程。该课程将由世界顶级珠宝品牌专业买手同时也是著名的宝石学家讲解。（您也可以参与艾格蓝宝独家推出的为期四天的宝石、钻石投资品鉴行程）</p>
                <p>下午将在全球前十大奢华百货巴黎春天，享受VIP购物体验。专业导购全程陪同，无需排队，所有购买的产品最后一次结算，专用高贵宾休息室和试衣间，专人协助办理退税手续，所有购买的产品专人提拿。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>在米其林餐厅享用美食。法国，厨师属于艺术家的范畴，数着米其林的“星星”吃大餐，在欧洲是一种很高级别的享受，即使是米其林一星，在欧美的餐饮界也已经是很高的荣耀。</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D4</div>
              <span>巴黎  名品街体验时尚</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">storefront</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">

            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>巴黎以她的美和时装享誉世界。尽管要和米兰共同分享世界时尚之都的称号，但只有在巴黎，您才能找到纯粹为时装而生的著名街道。因为世界顶级品牌旗舰店的进驻，她们又比别处有着更为浓郁的时尚气息。今天我们就要在佛不胜多喏黑大街去朝圣, 接受奢华的洗礼。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>塞纳河香槟游船晚宴是巴黎不可错过的行程。就在游船缓缓的向前驶去的同时，透过赏景玻璃，美轮美奂的历史遗迹一览无疑。在甲板船舱里，精美的法式佳肴的阵阵香气最符合当下的欢乐气氛。让风景如画的巴黎和精致诱惑的法式佳肴为您的巴黎之旅刻下永恒的回忆！</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D5</div>
              <span>巴黎-蓝色海岸</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">flight_takeoff</i>
              <i class="material-icons">festival</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>flight_takeoff</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>上午搭乘豪华航班从巴黎飞往尼斯</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>来到摩纳哥。摩纳哥气候宜人，风景秀丽；它的蓝色海湾上点缀着点点白色风帆，从高空俯瞰，红色屋顶栋栋别墅散落在深浅不一的绿色植物的海洋中；独具地中海风情的各色建筑辉耀闪烁，是个不能错过的好地方。被世人誉为摩纳哥百年传统守护神的王宫、周围陈列着路易十四时期铸造的炮台的王宫广场、海洋博物馆都是摩纳哥的特色景点。参观摩纳哥王子私人收藏古董老爷车博物馆。</p>
                <p>下午在蒙特卡洛体验最奢华且独特的感受。走进蒙特卡洛深海海水疗法中心，如同置身梦幻仙境，这里有高贵华丽的大理石装潢、淡色原木和水晶般的玻璃、全世界独一无二的深海海水疗法。置身在死海结晶盐的轻柔摩擦下身体开始放松，疲惫和不适在理疗师的手掌间消失殆尽。接下来是极其舒适的旋水按摩，在富含柔和浴油、亚洲鲜花及红白玫瑰花瓣的按摩池中，身体仿佛瞬间获得了活力与新生。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Hunyadi Étterem餐厅</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D6</div>
              <span>蓝色海岸 挥杆海天之间</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">theater_comedy</i>
              <i class="material-icons">sports_golf</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>在蒙特卡洛高尔夫私人俱乐部百年贵族球场享受挥杆的快乐。球场位于法国境内海拔900米高的阿杰尔山上，占地35公顷，18洞，71par，总长度5688米。蒙特卡洛高尔夫私人俱乐部由于位于山上而地势陡峭，但其周围风景极为优美, 背山面海，不管是对专业选手还是对普通球手，在这里打球都是令人愉悦的挑战.</p>
                <p>随后来到影城戛纳。戛纳小城依偎在青山脚下，濒临地中海之滨，它有5千米长的沙滩，四时有不谢之花。漫步城中，白色的楼房、蔚蓝的大海，以及一排排高大翠绿的棕榈树相互映衬，构成一幅美丽的自然风光。戛纳也是座购物名城，您可以在这里尽情自由购物。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>在尼斯老市场享受海鲜大餐。这里就像是法式的“大排档”，整个老市场都变成了热闹非凡的海鲜中心，来自世界各地的游客在这里大快朵颐，各式各样的海鲜任您选购。</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D7</div>
              <span>蓝色海岸 风情小镇</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">directions_bus</i>
              <i class="material-icons">storefront</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>directions_bus</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>乘坐私人豪华大巴前往埃兹</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>这个小镇建造房屋的方式独特，是在陡峭岩壁上建造的，很像鹫筑巢的方式，有鹫巢村之称。这里您能处处能感受到独特景致：石块砌成的房屋，拱著狭窄的巷道蜿蜒向上，风情无限。路两旁尽是古董店、画廊、纪念品店和餐厅。奥斯卡获奖影片《捉贼记》(To Catch a Thief)就是在这里拍摄的。</p>
                <p>随后出发前往被誉为最具有文化气息的山村：圣保罗芳斯。 一进城,就是弯曲的16世纪遗留下来的鹅卵石狭窄小径, 城墙内侧的小路围着小镇绕了一圈。小路的两旁装饰着现代陶瓷、凋刻和摆设现代艺术画家的画廊, 让现代艺术与古街融合出特殊的风貌。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Hunyadi Étterem餐厅</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D8</div>
              <span>摩纳哥-日内瓦 高级腕表</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">flight_takeoff</i>
              <i class="material-icons">storefront</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>flight_takeoff</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>上午搭乘豪华航班前往日内瓦</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>来到著名瑞士顶级腕表*高级手工制作坊，品牌形象大使亲自迎接，带领您参观顶级腕表，高级珠宝的制作程序，了解一件创世珍宝是如何在工艺师精湛的技艺下创作产生。随后在旗舰店内享受VIP贵宾服务。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Hunyadi Étterem餐厅</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Bo33家庭套房住宿代订</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D9</div>
              <span>日内瓦- 格斯塔德</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">directions_bus</i>
              <i class="material-icons">spa</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>directions_bus</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>早上出发前往洛伊克巴德</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>tour</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>这里的温泉很多有着超过40年的存储期，河流、雨雪和天露经过一定的积存，让矿物质格外充沛。</p>
                <p>来到这里，当然要体验体验独特的山间S p a。真正放松的状态是“ 和阿尔卑斯山的万物共呼吸” ， 按摩师会帮助你真正融合到格施塔德的自然美景中。其中值得推荐的是酒店和高山花园（ J a r d i n d e s Mo n t s ） 联合制作的一种独特疗法，使用高山花园品牌下1 0 0% 天然有机植物精油， 通过放松按摩， 让你感受到马郁兰舒緩、温暖和排毒的特性，将你带入大山和自然的怀抱。这种按摩方式对于中国人的身体可以起到很好的排毒作用。</p>
                <swiper
                  [slidesPerView]="3"
                  [spaceBetween]="50"
                  (swiper)="setControlledSwiper($event)"
                  [navigation]="{}"
                  [pagination]="{ clickable: true }"
                  [breakpoints]="{
                    '@0.00': {
                      slidesPerView: 1,
                      spaceBetween: 10
                    },
                    '@0.75': {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    '@1.00': {
                      slidesPerView: 3,
                      spaceBetween: 40
                    },
                    '@1.50': {
                      slidesPerView: 4,
                      spaceBetween: 50
                    },
                    '@2.00': {
                      slidesPerView: 5,
                      spaceBetween: 60
                    }
                  }"
                  [watchSlidesVisibility]="true"
                  [watchSlidesProgress]="true">
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-01.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-02.jpg"
                    />
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-03.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-04.jpg"/>
                  </ng-template>
                  <ng-template swiperSlide>
                    <img
                      class="swiper-lazy"
                      src="../assets/images/blog-05.jpg"/>
                  </ng-template>
                </swiper>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>local_dining</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>Hunyadi Étterem餐厅</p>
              </div>
            </div>
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>hotel</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>始建于1913年的瑞士格施塔德皇宫酒店（Gstaad Palace）是欧洲皇室与名流的传统度假地。酒店坐落于格施塔德山岗之上，俯视着这座美丽的山城，是眺望瑞士阿尔卑斯山脉壮丽风景的上佳地点。百年来，格施塔德皇宫酒店受到了无数明星的青睐。从开业至今，格施塔德皇宫酒店接待了包括著名影星奥黛丽•赫本、歌手艾尔顿•约翰、前美国总统吉米•卡特、歌手罗比•威廉姆斯、著名影星索菲娅•罗兰、伊丽莎白泰勒等诸多名人的下榻。</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="day-number">D10</div>
              <span>苏黎世 - 中国</span>
            </mat-panel-title>
            <mat-panel-description>
              <i class="material-icons">directions_bus</i>
              <i class="material-icons">flight_takeoff</i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="day">
            <div class="day__daily">
              <button mat-mini-fab>
                <mat-icon>flight_takeoff</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p>一觉睡到自然醒，出发前往苏黎世国际机场，踏上返程之路乘坐豪华航班返回中国</p>
              </div>
            </div>
            <div class="day__daily finish">
              <button mat-mini-fab>
                <mat-icon>home</mat-icon>
              </button>
              <div class="day__daily-gallery">
                <p></p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        -->
      </mat-accordion>
    </div>
  </div>
  <div id="price" class="section">
    <div class="container">
      <mat-toolbar class="price-toolbar">
        <i class="material-icons">payment</i><span>价格内容</span>
      </mat-toolbar>
      <div class="price-container">
        <div class="price-include">
          <h6>费用包含</h6>
          <ul>
            <li>全程住宿费（住宿全部以家庭为单位，不拼间）
              <ul>
                <li>具有特色的民宿农场或酒店</li>
              </ul>
            </li>
            <li>大巴费用（根据报名人数安排）
              <ul>
                <li>9座商务车</li>
                <li>20-40座观光旅游大巴</li>
              </ul>
            </li>
            <li>景区门票</li>
            <li>餐饮费</li>
          </ul>
        </div>
        <div class="price-exclude">
          <h6>费用不包含</h6>
          <ul>
            <li>护照与签证费</li>
            <li>境外保险</li>
            <li>往返机票</li>
            <li>超重行李托运费（如果产生）</li>
            <li>司机小费（每人每天2欧元）</li>
            <li>一切个人费用</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="notice" class="section">
    <div class="container">
      <mat-toolbar class="ideas-toolbar">
        <i class="material-icons">info</i><span>预定须知</span>
      </mat-toolbar>
      <div class="notice-container">
        <div class="info">
          <h6>儿童年龄</h6>
          <ul>
            <li>最好5岁以上，因为5岁以上的孩子身体可以更好的适应到欧洲10个小时的飞机旅程，有更好的认知接受能力和自理能力</li>
          </ul>
        </div>
        <div class="return">
          <h6>退改规则</h6>
          <ul>
            <li>已发生且不可退费用：系指您为购买旅行产品已经发生的费用以及向履行辅助人（如住宿代订、餐饮、交通工具、旅游景点服务提供方）支付且不可退还的费用、手续费等。</li>
            <li>旅游产品所包含的国际段机票代订，应当按照按照机票代订确认单的退改规则执行。</li>
            <li>旅游行程开始前，您可以将本产品中自身的权利义务转让给第三人，我社没有正当理由的不得拒绝（正当理由包括但不限于：已预订的旅行项目不可转让或退改、旅行项目对出行人身份、资格、身体状态等有特殊要求的等），因此增加的费用由您和第三人承担。</li>
            <li>我社所提供的旅行产品，您可以要求其中旅行元素的内容变更，但不可就其中部分项目要求单独退订。</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
