import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { AppComponent, DialogDataDialog, DialogData } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ContentDirective } from './directive/content.directive';

import { AbstractContentComponent } from './components/abstract.component';
import { HomeComponent } from './components/home/home.component';
import { CustomComponent } from './components/custom/custom.component';
import { ContactComponent } from './components/contact/contact.component';
import { PhotographyComponent } from './components/photography/photography.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { BusinessComponent } from './components/business/business.component';
import { StudyComponent } from './components/study/study.component';
import { BlogComponent } from './components/blog/blog.component';

// Shared
import { YoyDropdownComponent } from './shared/components/yoy-dropdown/yoy-dropdown.component';
import { YoyDaterangepickerComponent } from './shared/components/yoy-daterangepicker/yoy-daterangepicker.component';
import { YoyRoomSelectComponent, YoyRoomSelectDialogComponent } from './shared/components/yoy-room-select/yoy-room-select.component';
import { YoyCountrySelectComponent, YoyCountrySelectDialogComponent } from './shared/components/yoy-country-select/yoy-country-select.component';

// Material
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { SwiperModule } from 'swiper/angular';

// -- Pipe
import { YoySelectFilterPipePipe } from './shared/pipes/yoy-select-filter-pipe.pipe';

// Firestore
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

// Captcha
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    AppComponent,

    AbstractContentComponent,
    HomeComponent,
    CustomComponent,
    ContentDirective,
    ContactComponent,
    PhotographyComponent,
    ScheduleComponent,
    BusinessComponent,
    BlogComponent,

    YoyDropdownComponent,
    YoyDaterangepickerComponent,
    YoyRoomSelectComponent,
    YoyRoomSelectDialogComponent,
    YoyCountrySelectComponent,
    YoyCountrySelectDialogComponent,
    YoySelectFilterPipePipe,
    StudyComponent,
    BlogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,

    FormsModule,
    SwiperModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatCardModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSnackBarModule,

    ReactiveFormsModule,
    NgxCaptchaModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,

  ],
  entryComponents: [
    YoyRoomSelectDialogComponent,
    YoyCountrySelectDialogComponent
  ],
  providers: [
    MatDatepickerModule,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
