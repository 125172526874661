<label class="label-block"><i class="material-icons">{{icon}}</i>{{ label }}</label>
<div class="selection dropdown" [ngClass]="{'selection__changed': res?.length > 0}">
  <div  *ngIf="res?.length > 0; else all_options_template" class="selection__summary">
    <button class="selection__control" (click)="toggle()">
      <div class="wrapper">
        <i class="material-icons dropdown__icon">arrow_drop_down</i>
        <div class="selection__placeholder">{{ res[0].label }}</div>
        <div class="selection__count" *ngIf="res?.length > 1">{{res?.length}}</div>
      </div>
    </button>
    <span *ngIf="withall" class="reset" (click)="remove(res[0])">
        <i class="material-icons">close</i>
      </span>
  </div>
  <ng-template #all_options_template>
    <div class="selection__summary">
      <button class="selection__control" (click)="toggle()">
        <div class="wrapper">
          <i class="material-icons dropdown__icon">arrow_drop_down</i>
          <div class="selection__placeholder">暂不确定</div>
          <div class="selection__count" *ngIf="res?.length > 1">{{res?.length}}</div>
        </div>
      </button>
    </div>
  </ng-template>

  <div *ngIf="listVisible" class="dropdown__transparent-close" (click)="hide()"></div>
  <div *ngIf="listVisible" class="dropdown__list">
    <div *ngIf="options?.length < 1">
      暂无选项
    </div>
    <div *ngFor="let item of options" (click)="select(item)">
      <div class="content">{{item.label}}</div>
    </div>
  </div>
</div>
