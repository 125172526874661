import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading = true;
  constructor() { }

  showLoading(isShow: boolean): void {
    this.isLoading = isShow;
  }

  getLoading(): boolean {
    return this.isLoading;
  }
}
