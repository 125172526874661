import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  dataFactory: AngularFireList<any> = null;

  constructor( private db: AngularFireDatabase) {
  }

  getListByName(path: string): any {
    return this.db.list(path);
  }
}
