<!--BANNER SECTION START-->
<section class="banner-section inner-banner bg-dark">
  <div class="slide-img" style="background-image:url(../assets/images/inner-banner.jpg);"></div>
  <div class="inner-content">
    <div class="container">
      <h1>欧洲旅拍</h1>
      <ul class="breadcrumb">
        <li><a href="#">首页</a></li>
        <li class="active">欧洲旅拍</li>
      </ul><!--/.breadcrumb-->
    </div><!--/.container-->
  </div><!--/.inner-content-->
</section><!--/.banner-section-->
<!--BANNER SECTION END-->

<section id="main">
  <div class="blog-section section">
    <div class="container">
      <div class="blog-posts">
        <div class="row">
          <div class="column col-lg-8">
            <div class="primary-section">
              <div class="row">
                <div class="column col-lg-6" *ngFor="let item of blogSlideData" data-aos="fade-up">
                  <div class="blog-box">
                    <div class="blog-img-box">
                      <figure><a href="#" (click)="this.linkToUrl.emit('schedule')"><img src="../assets/images/{{item.url}}" alt=""></a></figure>
                      <span class="blog-category">{{item.category}}</span><!--/.blog-category-->
                    </div><!--/.blog-img-box-->
                    <div class="blog-info">
                      <div class="blog-details">
                        <span class="posted-date hr-primary">{{item.date}}</span><!--/.posted-date-->
                        <h3 class="post-title"><a href="#" (click)="this.linkToUrl.emit('schedule')">{{item.header}}</a></h3>
                        <p>{{item.content}}</p>
                      </div><!--/.blog-details-->
                      <div class="blog-bottom-row">
                        <span class="posted-by"><i class="fa fa-user" aria-hidden="true"></i> {{item.description}}</span><!--/.posted-by-->
                        <span class="comments"><i class="fa fa-comments-o" aria-hidden="true"></i> 08</span><!--/.comments-->
                      </div><!--/.blog-bottom-row-->
                    </div><!--/.blog-info-->
                  </div><!--/.blog-box-->
                </div><!--/.col-->
              </div><!--/.row-->
              <div class="pagination-section mt-20">
                <ul class="pagination">
                  <li><a href="#"><i class="fa fa-angle-left"></i></a></li>
                  <li class="current"><span>1</span></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#"><i class="fa fa-angle-right"></i></a></li>
                </ul><!--/.pagination-->
              </div><!--/.pagination-section-->
            </div><!--/.primary-section-->
          </div><!--/.col-->
          <div class="column col-lg-4">
            <aside id="sidebar">
              <div class="widget-single">
                <form>
                  <div class="search-section">
                    <input type="text" placeholder="Search here...">
                    <button value="Search" type="submit" class="btn-search"><i class="fa fa-search" aria-hidden="true"></i></button>
                  </div><!--/.search-section-->
                </form>
              </div><!--/.widget-single-->
              <div class="widget-single widget-categories">
                <h4 class="hr-primary">Categories</h4>
                <ul class="list">
                  <li><a href="#">婚礼跟拍</a> <span>08</span></li>
                  <li><a href="#">个人写真</a> <span>06</span></li>
                  <li><a href="#">商务报道</a> <span>12</span></li>
                </ul>
              </div><!--/.widget-single-->
              <div class="widget-single widget-recent-blog">
                <h4 class="hr-primary">Recent Blog</h4>
                <ul>
                  <li>
                    <figure>
                      <a href="#"><img src="../assets/images/latest-blog-01.jpeg" alt=""></a>
                    </figure>
                    <div class="post-summary">
                      <h5><a href="#"
                             (click)="this.linkToUrl.emit('schedule')">Sed ut perspiciatis unde omnis iste</a></h5>
                      <div class="posted-date">08 Jun, 2017</div><!--/.post-date-->
                    </div><!--/.post-summary-->
                  </li>
                  <li>
                    <figure>
                      <a href="#"><img src="../assets/images/latest-blog-02.jpeg" alt=""></a>
                    </figure>
                    <div class="post-summary">
                      <h5><a href="#"
                             (click)="this.linkToUrl.emit('schedule')">Sed ut perspiciatis unde omnis iste</a></h5>
                      <div class="posted-date">08 Jun, 2017</div><!--/.post-date-->
                    </div><!--/.post-summary-->
                  </li>
                  <li>
                    <figure>
                      <a href="#"
                         (click)="this.linkToUrl.emit('schedule')"><img src="../assets/images/latest-blog-03.jpeg" alt=""></a>
                    </figure>
                    <div class="post-summary">
                      <h5><a href="#">Sed ut perspiciatis unde omnis iste</a></h5>
                      <div class="posted-date">08 Jun, 2017</div><!--/.post-date-->
                    </div><!--/.post-summary-->
                  </li>
                </ul>
              </div><!--/.widget-single-->
              <div class="widget-single widget-tags">
                <h4 class="hr-primary">Popular Tag</h4>
                <ul class="taglist">
                  <li><a href="#">Business</a></li>
                  <li><a href="#">Photography</a></li>
                  <li><a href="#">Education</a></li>
                  <li><a href="#">News</a></li>
                  <li><a href="#">Design</a></li>
                  <li><a href="#">Event</a></li>
                  <li><a href="#">Web</a></li>
                </ul><!--/.taglist-->
              </div><!--/.widget-single-->
            </aside><!--/.sidebar-->
          </div>
        </div><!--/.row-->
      </div><!--/.blog-posts-->
    </div><!--/.container-->
  </div><!--/.blog-section-->
</section><!--/#main -->
