import { Component, OnInit, ViewChild, ComponentFactoryResolver, HostListener, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

// Directive
import { ContentDirective } from 'src/app/directive/content.directive';

// Component
import { AbstractContentComponent } from './components/abstract.component';
import { HomeComponent } from './components/home/home.component';
import { CustomComponent } from './components/custom/custom.component';
import { PhotographyComponent } from './components/photography/photography.component';
import { ContactComponent } from './components/contact/contact.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { BusinessComponent } from './components/business/business.component';
import { BlogComponent } from './components/blog/blog.component';
import { StudyComponent } from './components/study/study.component';
import { LoadingService } from 'src/app/services/index';
import { ActivatedRoute } from '@angular/router';

import * as AOS from 'aos';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { faUsers, faHeart, faBriefcase, faUserTie, faGrinHearts, faPlaneDeparture, faCameraRetro } from '@fortawesome/free-solid-svg-icons';

export interface DialogData {
  image: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeOutAnimation', [
      transition(':leave',
        animate('1s ease-in-out', style({opacity: 0}))
      )
    ]),
    trigger('fadeAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('.5s ease-in-out' )
      ]),
      transition(':leave',
        animate('.5s ease-in-out', style({opacity: 0}))
      )
    ])
  ]
})
export class AppComponent implements OnInit {

  @ViewChild(ContentDirective) contentHost: ContentDirective;

  title = '忆欧缘';
  whereAmI = 'home';
  showBacktop = false;
  showSearch = false;

  subnavY = 0;

  faUsers = faUsers;
  faHeart = faHeart;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faGrinHearts = faGrinHearts;
  faPlaneDeparture = faPlaneDeparture;
  faCameraRetro = faCameraRetro;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public  loadingService: LoadingService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    AOS.init();
    setTimeout((x) => {
      if (this.contentHost) {
        const viewContainerRef = this.contentHost.viewContainerRef;
        viewContainerRef.clear();

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HomeComponent);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        (componentRef.instance).linkToUrl.subscribe((url: string) => {
          viewContainerRef.clear();
          this.goToUrl(url);
        });
        (componentRef.instance).linkToSchedule.subscribe((url: string) => {
          viewContainerRef.clear();
          this.goToUrl('schedule', 3, url);
        });
        (componentRef.instance).linkToBlog.subscribe((url: string) => {
          viewContainerRef.clear();
          this.goToUrl('blog', 3, url);
        });
      }
    });
  }

  goToUrl(url: string, type = 3, name = 'de'): void {
    const viewContainerRef = this.contentHost.viewContainerRef;
    viewContainerRef.clear();

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(AbstractContentComponent);
    switch (url) {
      case 'home':
        this.whereAmI = 'home';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(HomeComponent);
        break;
      case 'custom':
        this.whereAmI = 'custom';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomComponent);
        break;
      case 'photography':
        this.whereAmI = 'photography';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(PhotographyComponent);
        break;
      case 'contact':
        this.whereAmI = 'contact';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(ContactComponent);
        break;
      case 'schedule':
        this.whereAmI = 'schedule';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(ScheduleComponent);
        break;
      case 'business':
        this.whereAmI = 'business';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(BusinessComponent);
        break;
      case 'study':
        this.whereAmI = 'study';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(StudyComponent);
        break;
      case 'blog':
        this.whereAmI = 'blog';
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(BlogComponent);
        break;
      default:
        break;
    }

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance).type = type;
    (componentRef.instance).name = name;
    (componentRef.instance).linkToUrl.subscribe((e: string) => {
      viewContainerRef.clear();
      this.goToUrl(e);
    });
    (componentRef.instance).linkToSchedule.subscribe((url: string) => {
      viewContainerRef.clear();
      this.goToUrl('schedule', 3, url);
    });
    (componentRef.instance).linkToBlog.subscribe((url: string) => {
      viewContainerRef.clear();
      this.goToUrl('blog', 3, url);
    });
  }

  openDialog(): void {
    this.dialog.open(DialogDataDialog, {
      width: '50%',
      maxWidth: '400px',
      data: {
        image: 'weixin',
      },
    });
  }
}

@Component({
  selector: 'dialog-data',
  templateUrl: 'dialog-data.html',
})
export class DialogDataDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
