import { Component, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract.component';
import { LoadingService } from 'src/app/services/index';
import { ScheduleItem, Schedules } from 'src/app/models/index';

// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
} from 'swiper/core';

import {HttpClient} from "@angular/common/http";

declare function setupSwiper(): void;

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent extends AbstractContentComponent implements OnInit {

  controlledSwiper: any;
  url = '';
  DataJson: Schedules;

  constructor(public loadingService: LoadingService, private http: HttpClient) {
    super();
    this.loadingService.showLoading(true);
  }

  ngOnInit(): void {
    this.initialLayout();
    this.url = "/assets/data/schedule_" + this.name + ".json";
    this.http.get(this.url).subscribe((res: Schedules) => {
      this.DataJson = res;
      this.loadingService.showLoading(false);
      setTimeout(() => {
        setupSwiper();
      })
    });
  }

  setControlledSwiper(swiper): void {
    this.controlledSwiper = swiper;
  }

}
